import React from 'react';
import SEO from '../utils/helmetWrapper';

export const AboutPage = () => (
  <>
    <SEO title="About" />
    <h1>About page</h1>
  </>
);

export default AboutPage;
